var render = function render(){var _vm=this,_c=_vm._self._c;return _c('x-modal-center',{attrs:{"bilingual":true,"apply-button-text":"save","cancel-button-text":"cancel","footer-class":"d-flex justify-content-between x-gap-1","apply-button-class":"w-100","cancel-button-class":"w-100","apply-button-loading":_vm.applyButtonLoading,"modal-container-style":{
    'max-width': '960px',
    'max-height': '720px',
    width: '75%',
    height: '100%',
    overflowY: 'scroll',
  }},on:{"close":_vm.closeCreatingModal,"cancel":_vm.closeCreatingModal,"apply":_vm.saveItem},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"x-font-size-36px font-craftworksans color-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("Add Facility"))+" ")])]},proxy:true},{key:"body",fn:function(){return [_c('h3',{staticClass:"mt-4 mb-2 status-pick-color-title"},[_vm._v(" "+_vm._s(_vm.$t("Image"))+" ")]),_c('div',{staticClass:"uploader-container flex-column"},[_c('div',{staticClass:"row uploader-row ml-0"},[(_vm.item.img)?_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"uploader"},[_c('div',{staticClass:"action-list"},[_c('div',{staticClass:"delete-plan",on:{"click":_vm.deleteImg}},[_c('base-delete-icon',{attrs:{"width":18,"height":18,"fill":"var(--white)"}})],1)]),_c('img',{attrs:{"src":_vm.createImage(),"alt":"img"}})])]):_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"uploader"},[_c('image-uploader',{attrs:{"multiple":false},on:{"upload-image":_vm.setImage}})],1)])])]),_c('h3',{staticClass:"mt-4 mb-2 status-pick-color-title"},[_vm._v(" "+_vm._s(_vm.$t("Name"))+" ")]),_c('validation-observer',{ref:"creating-observer",staticClass:"client-type-creating-body"},_vm._l((_vm.allLangs),function(lang){return _c('validation-provider',{key:lang,staticClass:"title-uz-provider",attrs:{"name":`www`}},[_c('x-form-input',{staticClass:"w-100",attrs:{"type":"text","placeholder":lang},model:{value:(_vm.item.name[lang]),callback:function ($$v) {_vm.$set(_vm.item.name, lang, $$v)},expression:"item.name[lang]"}})],1)}),1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }